import type { FC } from 'react';
import { Action, Alert } from '@reverse-hr/pattern-library';
import { Trans, useTranslation } from 'react-i18next';
import type { TJobLegacy } from '../../models/Job';
import { differenceInDays } from 'date-fns';
import { useContext } from 'react';
import { CurrentJobContext } from '../Providers/CurrentJobProvider';

interface TJobHeaderPresentationMeetingAlertProps {
  job: TJobLegacy | null;
  onSetPresentationMeetingDateClick: () => void;
}

export const JobHeaderPresentationMeetingAlert: FC<
  TJobHeaderPresentationMeetingAlertProps
> = ({ job, onSetPresentationMeetingDateClick }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'job.header.presentationMeetingAlert',
  });

  const { currentJob } = useContext(CurrentJobContext);

  const isVisible =
    job &&
    currentJob &&
    !job.presentationMeetingScheduledAt &&
    !currentJob.isInternal &&
    job.publish_date &&
    differenceInDays(new Date(), new Date(job.publish_date)) > 10;

  return isVisible ? (
    <div className="c-job-header-presentation-meeting-alert">
      <Alert
        closable={false}
        icon="icn-error-outline-24px"
        modifier="c-job-header-presentation-meeting-alert__alert"
        type="warning"
      >
        <div className="c-job-header-presentation-meeting-alert__content">
          <h5 className="c-job-header-presentation-meeting-alert__title">
            {t('title')}
          </h5>

          <p className="c-job-header-presentation-meeting-alert__text">
            <Trans i18nKey="job.header.presentationMeetingAlert.content" />
          </p>

          <div className="c-job-header-presentation-meeting-alert__footer">
            <Action
              icon="icn-chevron-right-24px"
              iconPosition="right"
              onClick={onSetPresentationMeetingDateClick}
              label={t('cta')}
              type="outline"
              size="small"
            />
          </div>
        </div>
      </Alert>
    </div>
  ) : null;
};
