import { utilityRequestPdfWithAuth } from '../utilities/session';

export const apiContractFetch = async (contractId: string) =>
  await utilityRequestPdfWithAuth({
    endpoint: `/api/v1/jarvis/contracts/${contractId}/downloads/signed-document`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
    },
  });
