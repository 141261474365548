import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkWrapper, PdfFileField } from '@reverse-hr/pattern-library';
import { OfferDetailsFormContext } from './OfferDetailsForm';
import { apiContractFetch } from '../../api/contract';

export const OfferDetailsFormContract = () => {
  const { t } = useTranslation(null, {
    keyPrefix: 'offerDetails.contractValues',
  });

  const { job } = useContext(OfferDetailsFormContext);

  const [contractUrl, setContractUrl] = useState(null);

  useEffect(() => {
    if (job.contractId) {
      const fetchContract = async () => {
        const response = await apiContractFetch(job.contractId);
        setContractUrl(response);
      };

      fetchContract();
    }
  }, [job.contractId]);

  return (
    <div className="c-offer-details-form__section c-offer-details-form-contract">
      <h3 className="c-offer-details-form__box-title">{t('title')}</h3>

      <div className="c-offer-details-form-contract__field">
        <p className="c-offer-details-form-contract__minimal-label">
          {t('minimal', {
            amount: job.successFeeMinimumValue,
            currency: job.currencySymbol,
          })}
        </p>

        <PdfFileField
          label={t('displayedFileName')}
          previewModalTitle={t('displayedFileName')}
          displayedFileName={t('displayedFileName')}
          downloadFileName={t('downloadFileName', { jobName: job.title })}
          downloadCtaText={t('downloadCta')}
          id="contract-details-contract"
          name="contract"
          file={contractUrl}
          isPreviewEnabled
          isDownloadEnabled
        />
      </div>
    </div>
  );
};
